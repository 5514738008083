/* Color Scheme */

.account-data {
  width: 800px;
}

.account-finder-search-button {
  width: 300px;
}

.account-finder-dropdown {
  margin-left: 5px;
}

body {
  margin: 0;
  background-color: #222;
  min-height: 100vh;
}

a {
  color: #8bc34a;
}

.system-message, .timer {
  background-color: #8bc34a;
  color: #fff;
  padding:10px;
  border-radius: 4px;
}

.system-message-failed{
  background-color: #e53935;
  color: #fff;
  padding:10px;
  border-radius: 4px;
}

.child-container {
  height: 100%;
}

.welcome-message {
  color: #fff;
}

.main-meny{
  background-color: #e53935;
}

.main-container {
  background-color: #222;
}

.test-passed {
  background-color: #8bc34a;
  color: #fff;
  border-radius: 4px;
}

.test-fail {
  background-color: #e53935;
  color: #fff;
  border-radius: 4px;
}

.test-separator {
  border: none;
  height: 1px;
  margin: 10px 0;
}

.test-label {
  margin: 5px;
}

.test-name {
  flex-grow: 1;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.test-details {
  font-size: 12px;
  font-weight: bold;
  background-color: #e53935;
  color: #fff;
  border-radius: 4px;
}

.tab1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tab2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin: 10px;
  width: 100%;
  height: 100%;
}

.tab3-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
}

.tab4-container {
  margin: 0 auto; /* Set left and right margins to auto */
  display: block; /* Ensure it behaves like a block element */
  width: 70%;
  height: 70%;
}

.tab5-container {
  margin: 0 auto; /* Set left and right margins to auto */
  display: block; /* Ensure it behaves like a block element */
  width: 70%;
  height: 70%;
}

.account-finder-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}

.tab3-subcontainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  color: white;
  background-color: #8bc34a;
  border-radius: 4px;
  width: 300px;
}

.tab1-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tab1-subrow {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.tab1-subrow > * {
  flex: 1;
}

.tab-content {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  width: 300px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}



.login-input {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.urgent-message {
  margin-top: 50px;
}

.urgent-message-title {
  width: 95%;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.urgent-message-message {
  width: 95%;
  height: 100px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  margin-top:10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #8bc34a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.maintenance-mode-slider {
  margin-left: 30px;
  width: 30%;
}

.tab-selectors {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.tab-selectors button {
  position: relative;
  padding: 10px 15px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
}

.tab-selectors button:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: all 0.3s ease;
}

.tab-selectors button.active {
  color: #8bc34a;
}

.tab-selectors button.active:before {
  background-color: #8bc34a;
}

.tab-selectors button:hover:before {
  background-color: #ddd;
}

.tab-selectors button:not(.active):hover {
  color: #777;
}

.tab-selectors button:not(.active):hover:before {
  background-color: #777;
}

.tab-selectors button:not(.active):before {
  background-color: transparent;
}

.tab-selectors .tab-button.active {
  background-color: dimgray;
  color: #fff;
}

.tab-selectors .tab-button:not(.active):hover {
  background-color: #ddd;
}

.tab-selectors .tab-button:not(.active) {
  background-color: transparent;
  color: #fff;
}
