.bar-graph {
    width: 300px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  .bar-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .timestamp {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: white
  }
  
  .bar-container {
    flex: 3;
    height: 15px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .bar {
    height: 100%;
    text-align: center;
    line-height: 15px;
    color: white;
    transition: width 0.6s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .bar.blue {
    background-image: linear-gradient(135deg, #00bcd4, #2196f3);
  }
  
  .bar.green {
    background-image: linear-gradient(135deg, #4caf50, #8bc34a);
  }
  
  .bar.orange {
    background-image: linear-gradient(135deg, #ff9800, #ff5722);
  }
  
  .bar.red {
    background-image: linear-gradient(135deg, #f44336, #e91e63);
  }
  