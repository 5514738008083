.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  animation: popup-fade-in 0.3s ease-in-out;
}

@keyframes popup-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup {
  background-color: #222;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 16px;
  animation: popup-slide-up 0.3s ease-in-out;
  font-size: 16px;
}

@keyframes popup-slide-up {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0);
  }
}

.popup-content {
  text-align: center;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 16px;
  color: #fff;
}

.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}

.popup-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.popup-buttons-publish {
  background-color: #e53935;
}

.popup-buttons-cancel {
  background-color: #8bc34a;
}

.popup-buttons button:hover {
  background-color: #6f6f6f;
}
